import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { setCartCount } from "../../Features/User/User";

import styles from "./index.module.scss";
import axios from "axios";
import { apiUrl } from "../../Constant";

function ThankYouMobile() {
  const dispatch = useDispatch();
  const [orderDetails, setOrderDetails] = useState(null);
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("orderId");

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  }

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await axios.get(
          `${apiUrl.SINGLE_ORDER_DETAILS}/${orderId}`
        );
        if (response) {
          setOrderDetails(response.data.data);
          dispatch(setCartCount(0));
        }
      } catch (error) {
        console.error("Error fetching order details:", error);
      }
    };

    fetchOrderDetails();
  }, [orderId]);

  if (!orderDetails) {
    return <div>Loading order details...</div>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <div className={styles.successIcon}>
          <span>✔</span>
        </div>
        <h1>Payment Successful!</h1>
        <p className={styles.message}>
          Thank you! Your payment of Rs. {orderDetails?.ActualPayment} has been
          received.
        </p>
        <p style={{ fontSize: 13 }}>
          <strong>Transaction ID:</strong> {orderDetails?.PaymentId}
        </p>

        <div className={styles.paymentDetails}>
          <div className={styles.detailRow}>
            <span>Date:</span>
            <span>{formatDate(orderDetails?.createdAt)}</span>
          </div>
          <div className={styles.detailRow}>
            <span>Order ID:</span>
            <span>{orderDetails?.orderId}</span>
          </div>

          <div className={styles.detailRow}>
            <span>Total Amount:</span>
            <span>₹ {orderDetails?.ActualPayment}</span>
          </div>
          <div className={styles.detailRow}>
            <span>Discount Price:</span>
            <span>₹ {orderDetails?.DiscountPrice || 0}</span>
          </div>
          <div className={styles.detailRow}>
            <span>Shipping Charges:</span>
            <span>₹ {orderDetails?.Shipping_Charge || 0}</span>
          </div>
          <div className={styles.detailRow}>
            <span>Payment Status:</span>
            <span>{orderDetails?.payment_status}</span>
          </div>
        </div>

        <p className={styles.contact}>
          Please email to budaiexclusive@gmail.com for any queries.
        </p>
      </div>
    </div>
  );
}

export default ThankYouMobile;
