export const routes = {
  otpUrl: "/otp",
  userUrl: "/user",
  search: "/search",
  faqUrl: "/faq",
  aboutUrl: "/about",
  refundUrl: "/refund",
  privacyUrl: "/privacy",
  contactUrl: "/contact",
  walletUrl: "/walletHistory",
  coinsUrl: "/rewardHistory",
  likeUrl: "/wishlist",
  orderUrl: "/order",
  signupUrl: "/signup",
  settingUrl: "/setting",
  homepageUrl: "/",
  shoppingUrl: "/cart",
  thankyouUrl: "/thankyou",
  walletAdded: "/walletAdded",
  thankyouMobileUrl: "/thankyouMobile",
  pendingPayment: "/payment-pending",
  paymentError:"/payment-error",
  addressUrl: "/address",
  catagoryUrl: "/product/:name",
  orderviewUrl: "/orderview",
  summeryUrl: "/order-summery",
  addcartUrl: "/product-detail/:name",
  notificationUrl: "/notificationHistory",
  error:'/error'
};
